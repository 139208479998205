<style scope>
.input-group {
  flex-direction: row;
}
.table td {
  vertical-align: inherit;
}
.table td:nth-child(n + 5) {
  min-width: 5rem;
}
@media (max-width: 1024px) {
  .input-group {
    flex-direction: column;
  }
}
</style>

<template>
  <b-container fluid>
    <b-row>
      <b-table
        :fields="fields"
        :items="items"
        :empty-text="$t('vehicle-acceptance-checkout.empty-table')"
        :caption="registrationNumber"
        show-empty
        caption-top
        responsive
      >
        <template v-slot:cell(thumbnailImage)="row">
          <thumbnail-image :imageId="row.item.imageId" />
        </template>
        <template v-slot:cell(serialNumbers)="row">
          <b-form-tags
            v-show="row.item.hasSerialNumber"
            :value="row.item.serialNumbers"
            :state="row.value.length === row.item.balance"
            :placeholder="$t('vehicle-acceptance-checkout.serial-number.add-serial-number')"
            :add-button-text="$t('vehicle-acceptance-checkout.serial-number.add-serial-number')"
            :duplicate-tag-text="$t('vehicle-acceptance-checkout.serial-number.duplicate-tags')"
            @input="x => (row.item.serialNumbers = x)"
          >
            <template v-slot="{ tags, disabled }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="(tag, index) in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    v-if="row.item.editHelper[index] != 'edit'"
                    @remove="openEdit(row, index)"
                    :title="tag"
                    :disabled="disabled"
                    :variant="stateToVariant(row, index)"
                    >{{ tag }}</b-form-tag
                  >
                  <v-select
                    :ref="`vs_${row.index}`"
                    v-show="row.item.editHelper[index] == 'edit'"
                    :appendToBody="true"
                    :clearable="false"
                    :options="serialStates"
                    v-model="row.item.serialState[index]"
                    @input="closeEdit(row, index)"
                    :reduce="x => x.key"
                  />
                </li>
              </ul>
            </template>
          </b-form-tags>
        </template>
        <template v-slot:cell(expended)="row">
          <b-input
            v-if="!isSaleableRow(row)"
            type="number"
            min="0"
            :max="row.item.count"
            v-model.number="row.item.expended"
            @change="onExpendedInput(row.item, row.value)"
            @click="$event.target.select()"
          />
          <div v-else>
            <b-row>
              <b-col>
                <b-input-group>
                  <b class="text-info mt-1 mr-2">
                    {{ $t("vehicle-acceptance-checkout.fields.service") }}
                  </b>
                  <b-input
                    class="w-100"
                    type="number"
                    min="0"
                    :max="row.item.count - row.item.sold"
                    v-model.number="row.item.service"
                    @change="onSoldOrServicedInput(row.item)"
                    @click="$event.target.select()"
                  />
                </b-input-group>
              </b-col>
              <b-col>
                <b-input-group>
                  <b class="text-info mt-1 mr-2">
                    {{ $t("vehicle-acceptance-checkout.fields.sale") }}
                  </b>
                  <b-input
                    class="w-100"
                    type="number"
                    min="0"
                    :max="row.item.count - row.item.service"
                    v-model.number="row.item.sold"
                    @change="onSoldOrServicedInput(row.item, row.value)"
                    @click="$event.target.select()"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-slot:cell(balance)="row">
          <b-input
            type="number"
            min="0"
            :max="row.item.count"
            v-model.number="row.item.balance"
            @change="onBalanceInput(row.item, row.value)"
            @click="$event.target.select()"
            :disabled="isSaleableRow(row)"
          />
        </template>
      </b-table>
      <div class="mx-2">
        <b-button @click="onNextClicked" :disabled="!validVehicleAcceptance">
          {{ $t("vehicle-acceptance-checkout.next-btn") }}
        </b-button>
        <b-button class="ml-2" variant="danger" @click="onCancel">
          {{ $t("vehicle-acceptance-checkout.cancel-btn") }}
        </b-button>
      </div>
      <custom-check-modal
        ref="customCheckModal"
        @next-clicked="onCustomCheckModalNextClicked"
        @back-clicked="onBackClicked"
      />
      <image-modal />
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import CustomCheckModal from "./CustomCheckModal.vue";
import { VEHICLE_ACCEPTANCE_OR_CHECKOUT } from "@/const/events.js";

import auth from "@/services/auth.js";
import { DRIVER } from "@/const/user-role.js";
import { ACTIVE, LOST, BROKEN } from "@/const/sn-item-statuses.js";
import ImageModal from "@/components/goods-images/ImageModal.vue";
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";
import Vue from "vue";

export default {
  components: {
    CustomCheckModal,
    "image-modal": ImageModal,
    "thumbnail-image": ThumbnailImage
  },
  props: {
    vehicleId: { required: true, type: Number },
    checkout: { type: Boolean }
  },
  data() {
    return {
      items: [],
      customChecks: [],
      customCheckResults: [],
      initialState: [],
      registrationNumber: ""
    };
  },
  computed: {
    serialStates() {
      return [
        {
          label: this.$t(`sn-item-status.statuses.${ACTIVE}`),
          key: ACTIVE
        },
        {
          label: this.$t(`sn-item-status.statuses.${LOST}`),
          key: LOST
        },
        {
          label: this.$t(`sn-item-status.statuses.${BROKEN}`),
          key: BROKEN
        }
      ];
    },
    validVehicleAcceptance() {
      return this.items.every(
        x =>
          x.balance >= 0 &&
          x.balance <= x.count &&
          x.expended >= 0 &&
          x.expended <= x.count &&
          x.sold >= 0 &&
          x.service >= 0 &&
          ((x.hasSerialNumber && x.serialNumbers.length === x.balance) || !x.hasSerialNumber)
      );
    },
    fields() {
      let acceptanceOrCheckoutValue = [
        { key: "thumbnailImage", label: "" },
        { key: "title", label: this.$t("vehicle-acceptance-checkout.fields.title") },
        { key: "serialNumbers", label: this.$t("vehicle-acceptance-checkout.fields.serialNumbers") },
        {
          key: "count",
          label: this.$t("vehicle-acceptance-checkout.fields.count." + (this.checkout ? "checkout" : "acceptance"))
        },
        { key: "expended", label: this.$t("vehicle-acceptance-checkout.fields.expended") },
        { key: "balance", label: this.$t("vehicle-acceptance-checkout.fields.balance") }
      ];
      if (!this.checkout) return acceptanceOrCheckoutValue.filter(x => x.key !== "expended");
      return acceptanceOrCheckoutValue;
    }
  },
  methods: {
    ...mapActions(["vehicleLoad/getVehicleLoadItems", "template/getTemplateForVehicle", "goods/getGoodsById"]),
    stateToVariant(row, index) {
      var state = row.item.serialState[index];

      if (state == LOST) return "secondary";
      if (state == BROKEN) return "danger";
      if (state == ACTIVE) return "info";

      return "";
    },
    closeEdit(row, index) {
      Vue.set(row.item.editHelper, index, "");
    },
    openEdit(row, index) {
      for (var i = 0; i < this.items.length; i++)
        for (var j = 0; j < this.items[i].editHelper.length; j++) Vue.set(this.items[i].editHelper, j, "");

      Vue.set(row.item.editHelper, index, "edit");

      //we need this full tick between showing v-select and opening it
      this.$nextTick(function() {
        Vue.set(row.item.serialState, index, "");
      });

      this.$nextTick(function() {
        this.$refs[`vs_${row.index}`][index].open = true;
      });
    },
    isSaleableRow(row) {
      return this.checkout && auth.userRole() == DRIVER && row.item.isSaleable;
    },
    onBalanceInput(row, balance) {
      row.expended = row.count - balance;
    },
    onExpendedInput(row, expended) {
      row.balance = row.count - expended;
    },
    onSoldOrServicedInput(row) {
      row.balance = row.count - row.sold - row.service;
    },
    onCancel() {
      this.items = JSON.parse(JSON.stringify(this.initialState));
    },
    onNextClicked() {
      if (this.customChecks.length > 0) {
        let previousCustomCheck = this.customChecks.find(x => x.order === 0);
        previousCustomCheck.prevCheckId = previousCustomCheck.id;
        this.$refs.customCheckModal.setinitialState(previousCustomCheck);
        this.$refs.customCheckModal.show();
      } else {
        this.finish();
      }
    },
    onCustomCheckModalNextClicked({ customCheckId, checkResults }) {
      let existingResult = this.customCheckResults.find(x => x.customCheckId === customCheckId);
      if (existingResult) {
        existingResult.checkResults = checkResults;
      } else {
        this.customCheckResults.push({ customCheckId, checkResults });
      }
      let order = this.customChecks.find(x => x.id === customCheckId).order;
      let nextCustomCheck = this.customChecks.find(x => x.order === order + 1);
      if (nextCustomCheck) {
        nextCustomCheck.prevCheckId = customCheckId;
        this.$refs.customCheckModal.setinitialState(nextCustomCheck);
      } else {
        this.finish();
        this.$refs.customCheckModal.hide();
      }
    },
    onBackClicked({ customCheckHistory, customCheckCurrId }) {
      let customCheckTmp = customCheckHistory.find(x => x.id === customCheckCurrId);
      if (customCheckTmp) {
        let customCheckId = customCheckTmp.prevId;
        let previousCustomCheck = this.customChecks.find(x => x.id === customCheckId);
        if (previousCustomCheck) {
          let prevResult = this.customCheckResults.find(x => x.customCheckId === customCheckId);
          if (prevResult) {
            let previousChecks = {
              title: previousCustomCheck.title,
              order: previousCustomCheck.order,
              customCheckId: previousCustomCheck.id,
              checkResults: prevResult.checkResults
            };
            this.$refs.customCheckModal.previousCheck(previousChecks);
          }
        }
      }
    },
    finish() {
      var sales = [];
      if (this.checkout)
        sales = this.items
          .filter(x => x.isSaleable && x.sold > 0)
          .map(x => {
            return {
              goodsId: x.goodsId,
              sold: x.sold
            };
          });
      let loadItems = this.items.map(x => {
        return {
          count: x.balance,
          goodsId: x.goodsId,
          serialNumbers: x.serialNumbers,
          serialStates: x.serialState
        };
      });
      let customCheckResults = this.customCheckResults.map(x => {
        return {
          id: x.customCheckId,
          checkResults: x.checkResults.map(y => {
            return {
              customCheckItemId: y.checkId,
              passed: y.passed
            };
          })
        };
      });
      this.$bus.emit(VEHICLE_ACCEPTANCE_OR_CHECKOUT, {
        loadItems,
        customCheckResults,
        sales
      });
    },
    initTableItems(loadItems, goods) {
      let goodsIdList = goods.map(x => x.goods.id);
      let promises = loadItems
        .filter(x => !goodsIdList.includes(x.goodsId))
        .map(x => this["goods/getGoodsById"](x.goodsId));

      Promise.all(promises).then(value => {
        let goodsData = [
          ...goods,
          ...value.map(x => {
            return { goods: x.data };
          })
        ];

        this.items = loadItems.map(x => {
          let goodsItem = goodsData.find(y => y.goods.id == x.goodsId);
          return {
            title: goodsItem.goods.title[this.$i18n.locale],
            imageId: goodsItem.goods.imageId,
            goodsId: goodsItem.goods.id,
            serialNumbers: x.serialNumbers != null ? x.serialNumbers : [],
            serialState: x.serialNumbers != null ? x.serialStates : [],
            editHelper: x.serialNumbers != null ? new Array(x.serialNumbers.length).fill("") : [],
            count: x.count,
            expended: 0,
            service: 0,
            sold: 0,
            balance: x.count,
            hasSerialNumber: goodsItem.goods.hasSerialNumber,
            isSaleable: goodsItem.goods.isSaleable
          };
        });

        this.initialState = JSON.parse(JSON.stringify(this.items));
      });
    },
    getRegistrationNumber() {
      this.$store
        .dispatch("vehicle/getRegistrationNumber", this.vehicleId)
        .then(regNumber => (this.registrationNumber = regNumber));
    },
    notifyIncidents(incidents) {
      if (incidents.length == 0) return;
      var links = new Array(incidents.length);
      for (let i = 0; i < incidents.length; i++)
        links[i] = this.$createElement(
          "b-link",
          { class: "toast-body router-link-active", props: { to: `/incidents/${incidents[i].id}`, target: "_blank" } },
          this.$t(`incident-type.${incidents[i].type}`)
        );
      this.$root.$bvToast.toast(links, {
        title: this.$t("incident.auto-toast-title"),
        autoHideDelay: 500000,
        appendToast: true
      });
    }
  },
  mounted() {
    this.getRegistrationNumber();
    let vehicleLoadItemsPromise = this["vehicleLoad/getVehicleLoadItems"](this.vehicleId);
    let templateForVehiclePromise = this["template/getTemplateForVehicle"](this.vehicleId);
    Promise.all([vehicleLoadItemsPromise, templateForVehiclePromise])
      .then(value => {
        let loadItems = value[0].data;
        let goods = value[1].data.goodsTemplates || [];
        this.customChecks = value[1].data.customChecks || [];
        this.initTableItems(loadItems, goods);
      })
      .catch(err => {
        if (err.data.errorCode == "error-getting-vehicle-load") {
          alert(this.$t("vehicle-load.error.error-getting-vehicle-load"));
          this.$router.go(-1);
        } else alert(this.$t("vehicle-acceptance-checkout.error.error-while-loading-equipment-and-template"));
      });
  }
};
</script>
