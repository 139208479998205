<template>
  <b-modal
    id="custom-check-modal"
    :title="title[$i18n.locale]"
    @ok.prevent="onNextClicked"
    :ok-title="$t('vehicle-acceptance-checkout.custom-check.ok-title')"
    :cancel-title="$t('vehicle-acceptance-checkout.custom-check.cancel-title')"
    :ok-disabled="!canClickNextBtn"
    size="lg"
  >
    <b-table :items="checkResults" :fields="fields">
      <template v-slot:cell(title)="row">{{ row.value[$i18n.locale] }}</template>
      <template v-slot:cell(passed)="row">
        <b-row>
          <b-form-checkbox
            :checked="row.item.passed == true"
            @change="onPassedChange($event, row.index)"
            class="mr-2"
            size="lg"
          ></b-form-checkbox>
        </b-row>
      </template>
      <template v-slot:cell(not-passed)="row">
        <b-row>
          <b-form-checkbox
            :checked="row.item.passed == false"
            @change="onNotPassedChange($event, row.index)"
            class="mr-2"
            size="lg"
          ></b-form-checkbox>
        </b-row>
      </template>
    </b-table>
    <div class="d-flex justify-content-end">
      <b-button @click="onBackClicked" :disabled="this.order == 0">
        {{ $t("vehicle-acceptance-checkout.custom-check.table.back") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      title: {},
      customCheckId: null,
      checkResults: [],
      customCheckHistory: [],
      order: ""
    };
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("vehicle-acceptance-checkout.custom-check.table.title") },
        { key: "passed", label: this.$t("vehicle-acceptance-checkout.custom-check.table.passed") },
        { key: "not-passed", label: this.$t("vehicle-acceptance-checkout.custom-check.table.not-passed") }
      ];
    },
    canClickNextBtn() {
      return this.checkResults.every(x => x.passed !== null);
    }
  },
  methods: {
    setinitialState(customCheck) {
      this.title = customCheck.title;
      this.order = customCheck.order;
      this.customCheckId = customCheck.id;
      this.customCheckHistory.push({ id: customCheck.id, prevId: customCheck.prevCheckId });
      this.checkResults = customCheck.checkItems.map(x => {
        return {
          checkId: x.id,
          title: x.title,
          passed: null
        };
      });
    },
    previousCheck(customCheck) {
      this.title = customCheck.title;
      this.order = customCheck.order;
      this.customCheckId = customCheck.customCheckId;
      this.checkResults = customCheck.checkResults.map(x => {
        return {
          checkId: x.checkId,
          title: x.title,
          passed: x.passed
        };
      });
    },
    onPassedChange(evt, i) {
      let itemToChange = this.checkResults[i];
      itemToChange.passed = evt ? true : null;
      Vue.set(this.checkResults, i, itemToChange);
    },
    onNotPassedChange(evt, i) {
      let itemToChange = this.checkResults[i];
      itemToChange.passed = evt ? false : null;
      Vue.set(this.checkResults, i, itemToChange);
    },
    onNextClicked() {
      this.$emit("next-clicked", { customCheckId: this.customCheckId, checkResults: this.checkResults });
    },
    onBackClicked() {
      this.$emit("back-clicked", {
        customCheckHistory: this.customCheckHistory,
        customCheckCurrId: this.customCheckId
      });
    },
    show() {
      this.$bvModal.show("custom-check-modal");
    },
    hide() {
      this.$nextTick(() => {
        this.$bvModal.hide("custom-check-modal");
      });
    }
  }
};
</script>
